import { HttpHeaders } from '@angular/common/http';

export const httpHeaders = {
    headers:new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'X-Token',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
        // 'Access-Control-Expose-Headers': 'X-Auth-Tokens'
        // 'X-Token': 'string'
    }),
    fileHeaders:new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        'Host':'<calculated when request is sent>',
        "Accept":"*/*",
        'Access-Control-Allow-Headers': 'X-Token',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
    })
}