import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from 'ng-click-outside';

import { PipeModule } from 'app/shared/pipes/pipe.module';
import { FileUploadModule } from "ng2-file-upload";

//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { VerticalMenuComponent } from "./vertical-menu/vertical-menu.component";

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { TopMenuDirective } from './directives/topmenu.directive';
import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';
import { AutocompleteModule } from "./components/autocomplete/autocomplete.module";
import { AutofocusDirective } from "./directives/autofocus.directives";
import { AccessControlDirective } from "./directives/access-control.directive";
import { DragAndDropComponent } from "./drag-and-drop/drag-and-drop.component";


@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        AutofocusDirective,
        TopMenuDirective,
        NgbModule,
        DragAndDropComponent,
        TranslateModule,
        AccessControlDirective,
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        OverlayModule,
        ReactiveFormsModule ,
        PerfectScrollbarModule,
        AutocompleteModule,
        ClickOutsideModule,
        FileUploadModule,
        PipeModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        AccessControlDirective,
        DragAndDropComponent,
        VerticalMenuComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        AutofocusDirective,
        TopMenuLinkDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
    ]
})
export class SharedModule { }
