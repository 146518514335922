import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import {AuthService} from "../auth/auth.service";
import {User} from "../../user/user.model";

@Directive({
  selector: '[accessControl]'
})
export class AccessControlDirective implements OnInit {

  @Input() accessControl: string[];
  user: User;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private _authService: AuthService,
    private templateRef: TemplateRef<any>,
  ) {
    this.user = this._authService.readUserFromLocalStorage();
  }


  ngOnInit(): void {
    this.viewContainerRef.clear();
    if (this.accessControl && this.accessControl.includes(this.user.role)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}
