<div class="confirm-modal">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <i class="ft-x-circle text-primary cursor-pointer font-large-1"
       aria-label="Close" (click)="cancel()"></i>
  </div>
  <div class="modal-body">
    {{text}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn bg-secondary text-white" (click)="cancel()">{{'global.cancel' | translate}}</button>
    <button type="button" class="btn bg-primary text-white" (click)="confirm()">{{'global.confirm' | translate}}</button>
  </div>
</div>
