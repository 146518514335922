import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef, Input
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import { FileUploader } from 'ng2-file-upload';

@Component({
    selector: 'app-drag-and-drop-component',
    templateUrl: './drag-and-drop.component.html',
    styleUrls: ['./drag-and-drop.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DragAndDropComponent implements OnInit {
  @Input() acceptedFileTypes: string[];
  acceptedFileType = '';
  inputId;
  disabled;
  @Input() onFileSelected
  @Input() params
  @Input() multiple

  uploader:FileUploader
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;
  response:string;
  constructor(
    private activeRoute: ActivatedRoute,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private toaster: ToastrService,
  ) {
    this.uploader = new FileUploader({
      autoUpload: false,
    });
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
  }

  ngOnInit() {
    if (this.acceptedFileTypes) {
      this.acceptedFileType = this.acceptedFileTypes.join(', ');
    }
  }

  fileSelected($event) {
    const selectedFiles = Array.from($event);
    if (this.acceptedFileTypes) {
      let allFilesIsCorrectTypes = true;
      selectedFiles.forEach((file: any) => {
        console.log(file.type)
        if (!this.acceptedFileTypes.includes(file.type)) {
          allFilesIsCorrectTypes = false
        }
      })
      if (!allFilesIsCorrectTypes) {
        this.toaster.error(this.translate.instant('global.fileTypeNotSupported'));
        return;
      }
    }
    this.onFileSelected({
      files: selectedFiles,
      params: this.params
    })
  }

}
