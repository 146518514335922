import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private router:Router
  ){}

  async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot): Promise<boolean> {
    if (this._authService.isLoggedIn()) {
      if (this._authService.isTokenExpired()) {
        return await this._authService.refreshTokenTrigger() ? true: this.router.navigate(["/"]);
      } else {
        return true;
      }
    } else {
      await this.router.navigate(["/"], {queryParams:{returnUrl:state.url}});
    }
    return new Promise(res => res(true))
  }
}
