import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import {constants} from "../../../environments/constants";

@Injectable({
  providedIn: 'root'
})
export class JWTTokenInterceptor implements HttpInterceptor{

    private isRefreshing = false;
    private refreshTokenSubject:BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private _authService:AuthService,
        private _router:Router,
    ){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem(constants.NOINETWORK_TOKEN);
        if (token) {
            const cloned = this.addToken(req, token);
            return next.handle(cloned).pipe(catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401 && !error.url.includes("refresh-token")) {
                  return this.handleUnauthorizeError(req,next);
                } else if (error.url.includes("refresh-token")) {
                  this._router.navigate(["/session_expired"], {replaceUrl: true});
                } else {
                  return throwError(error);
                }
            }));
        } else {
            return next.handle(req);
        }
    }

    private addToken(req:HttpRequest<any>, token:string){
        return req.clone({
            headers:req.headers.set("Authorization", "Bearer " + token)
        });
    }

    private handleUnauthorizeError(request: HttpRequest<any>, next: HttpHandler){
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this._authService.refreshToken().pipe(
                switchMap((token: any) => {
                this.isRefreshing = false;
                this.refreshTokenSubject.next(token);
                return next.handle(this.addToken(request, token));
            }));

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                return next.handle(this.addToken(request, jwt));
            }));
        }
    }
}
