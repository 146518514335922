import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from "./confirm.component";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  title: string;

  constructor(
    public translate: TranslateService,
    private modalService: NgbModal
  ) {
    this.title = this.translate.instant('global.confirmDeleteTitle');
  }

  public confirm({title, text}: any) {
    const modalRef = this.modalService.open(ConfirmComponent, {
      windowClass: 'confirm-modal',
      centered: true
    });
    modalRef.componentInstance.title = title || this.title;
    modalRef.componentInstance.text = text;
    return modalRef.result
  }

}
