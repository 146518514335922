import {
  Component, Input,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"]
})
export class ConfirmComponent implements OnInit {


  @Input() title: string;
  @Input() text: string;
  constructor(
    private activeModal: NgbActiveModal
  ) {

  }

  ngOnInit() {

  }

  confirm() {
    this.activeModal.close();
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
