import { HttpClient } from "@angular/common/http";
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { httpHeaders } from '../../../environments/httpHeaders';
import { Injectable } from "@angular/core";
import { constants } from "../../../environments/constants";
import { User } from "../../user/user.model";
import { BehaviorSubject } from "rxjs";

const apiUrl = environment.apiUrl;
const headers = httpHeaders.headers;
const NOINETWORK_YEAR = 'NOINETWORK_YEAR';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user;
  public emailToChangePassword = '';
  public selectedYear = new BehaviorSubject(this.readYearFromLocalStorage());

  constructor(
    private _http:HttpClient,
    private jwtHelper:JwtHelperService
  ) {
    this.user = this.readUserFromLocalStorage();
  }

  public getSelectedYear() {
    return this.selectedYear
  }

  public updateYear(year) {
    this.saveYearToLocalStorage(year)
    return this.selectedYear.next(year);
  }

  public readUserFromLocalStorage() {
    try {
      return JSON.parse(localStorage.getItem(constants.NOINETWORK_USER))
    } catch (e) {
      return null;
    }
  }

  public readYearFromLocalStorage() {
    return localStorage.getItem(NOINETWORK_YEAR) || new Date().getFullYear();
  }

  public saveYearToLocalStorage(year) {
    return localStorage.setItem(NOINETWORK_YEAR, year);
  }

  public signInUser(data){
    const endPoint = "/api/token";

    return this._http.post(apiUrl + endPoint, data, {headers:headers, observe:'response'}).pipe(
      map(response => {
          this.user = new User(response.body);
          if(this.user && this.user.accessToken){
            localStorage.setItem(constants.NOINETWORK_USER, JSON.stringify(this.user));
            localStorage.setItem(constants.NOINETWORK_TOKEN, this.user.accessToken);
            localStorage.setItem(constants.NOINETWORK_TOKEN_REFRESH, this.user.refreshToken);
          }
          return this.user;
        }
      )
    );
  }

  public recoverPassword(email){
    const endPoint = "/api/admin/forgotpassword";
    const request = {
      email:email
    };
    return this._http.post(apiUrl + endPoint, request, {headers:headers});
  }

  public changePassword(data){
    const endPoint = "/api/admin/changepassword";
    return this._http.post(apiUrl + endPoint, data, {headers:headers});
  }

  public resetPassword(resetToken, newPassword){
    const endPoint = "/api/admin/resetpassword";
    const request = {
      resetToken,
      newPassword
    };
    return this._http.post(apiUrl + endPoint, request, {headers:headers});
  }

  public async logOutUser(){
    if(this.user && this.user.accessToken){
      const endPoint = "/api/token/logout";
      await this._http.post(apiUrl + endPoint, null, {headers:headers, observe:"response"}).toPromise();
    }
    this.clearStorage();
  }

  public clearStorage() {
    localStorage.removeItem(constants.NOINETWORK_USER);
    localStorage.removeItem(constants.NOINETWORK_TOKEN);
    localStorage.removeItem(constants.NOINETWORK_TOKEN_REFRESH);
  }

  public isLoggedIn(){
    this.user = this.readUserFromLocalStorage();
    return !!this.user && (this.user.accessToken);
  }

  public isTokenExpired(){
    return this.jwtHelper.isTokenExpired(this.user.accessToken);
  }

  public refreshToken(){
    const endPoint = "/api/token/refresh-token";
    const request = {
      "refreshToken":this.user.refreshToken
    }
    return this._http.post(apiUrl + endPoint, request, {headers:headers, observe:"response"}).pipe(map((resp:any) => {
      this.user.accessToken = resp.body.accessToken;
      this.user.refreshToken = resp.body.refreshToken;
      localStorage.setItem(constants.NOINETWORK_USER, JSON.stringify(this.user));
      localStorage.setItem(constants.NOINETWORK_TOKEN, this.user.accessToken);
      localStorage.setItem(constants.NOINETWORK_TOKEN_REFRESH, this.user.refreshToken);
      return this.user.accessToken;
    }, error => {
      return false;
    }));
  }

  async refreshTokenTrigger() {
    return await this.refreshToken().toPromise();
  }

}
