import {Spinner} from "ngx-spinner/lib/ngx-spinner.enum";

export const constants = {
  NOINETWORK_USER: 'NOINETWORK_USER',
  NOINETWORK_TOKEN: 'NOINETWORK_TOKEN',
  NOINETWORK_TOKEN_REFRESH: 'NOINETWORK_TOKEN_REFRESH',
  LANG_KEY: 'it',
  START_YEAR_FOR_FILTER: 2023,
};

export const spinnersOptions:Spinner = {
  type: 'ball-triangle-path',
  size: 'medium',
  bdColor: 'rgba(0, 0, 0, 0.8)',
  color: '#fff',
  fullScreen: true
}

export const userRoles = [
  {
    key: 'AD',
    name: 'users.roles.administrator'
  },
  {
    key: 'PA',
    name: 'users.roles.partner'
  },
  {
    key: 'OF',
    name: 'users.roles.office'
  },
];

export const userRoleNames = {
  AD: 'AD',
  PA: 'PA',
  OF: 'OF',
};

export const userRolesAliases = {
  'AD': 'administrator',
  'PA': 'partner',
  'OF': 'office',
};

export const userRolesKeys = {
  'administrator': 'AD',
  'partner': 'PA',
  'office': 'OF',
};
