import { RouteInfo } from './vertical-menu.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'navigation.dashboard',
    icon: 'ft-home',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: ['AD', 'PA', 'OF']
  },
  {
    path: '/contracts',
    title: 'navigation.contracts',
    icon: 'ft-file-text',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: ['AD', 'PA', 'OF'],
  },
  {
    path: '/search',
    title: 'navigation.search',
    icon: 'ft-search',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: ['AD', 'PA', 'OF'],
  },
  {
    path: '/stats',
    title: 'navigation.stats',
    icon: 'ft-bar-chart',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: ['AD', 'PA'],
  },
  {
    path: '/stats-total',
    title: 'navigation.statsTotal',
    icon: 'ft-bar-chart-2',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: ['AD'],
  },
  {
    path: '/fees',
    title: 'navigation.fees',
    icon: 'ft-credit-card',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: ['AD'],
  },
  {
    path: '/users',
    title: 'navigation.users',
    icon: 'ft-users',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: ['AD'],
  },
];
