import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { FilterPipe } from './filter.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';
import {HighlighterPipe} from "./highlighter.pipe";

@NgModule({
  declarations:[FilterPipe, SearchPipe, ShortNamePipe, HighlighterPipe],
  imports:[CommonModule],
  exports:[FilterPipe, SearchPipe, ShortNamePipe, HighlighterPipe]
})

export class PipeModule{}
